import { useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Card,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./CaseConfig.module.scss";
import Header from "../../../../shared/components/Header/Header";
import LoadingButton from "../../../../shared/components/LoadingButton/LoadingButton";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { CaseHandlerTypes, MemberReportConfigType } from "../../interfaces";
import CaseConfigLoader from "./CaseConfigLoader";
enum CaseTypes {
  lesserThan = "lesserThan",
  greaterThan = "greaterThan",
  betweenAnd = "betweenAnd",
}

interface CaseConfigFormProps {
  title: string;
  description: string;
  configName: CaseHandlerTypes;
  configLabel: string;
  configData: MemberReportConfigType | null;
  handleConfigSave: (data: MemberReportConfigType) => Promise<void>;
  isLoading: boolean;
}

export default function CaseConfigForm({
  title,
  description,
  configName,
  configLabel,
  configData,
  handleConfigSave,
  isLoading,
}: CaseConfigFormProps) {
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    values: configData && configData[configName] ? configData : undefined,
    defaultValues: {
      [configName]: [
        { type: CaseTypes.lesserThan, value: [0] },
        { type: CaseTypes.betweenAnd, value: [0, 0] },
        { type: CaseTypes.greaterThan, value: [0] },
      ],
    },
  });
  const onSubmit = async (data: any) => {
    const displayNames = data[configName]
      .map((field: any) => field.tag)
      .filter((name: string) => name !== "" && name !== undefined);
    const uniqueDisplayNames = new Set(displayNames);

    if (uniqueDisplayNames.size !== displayNames.length) {
      toast.error("Display names must be unique.");
      return;
    }
    const validationResult = validateCaseConditions(data[configName]);
    if (validationResult !== true) {
      toast.error(validationResult);
      return;
    }
    return handleConfigSave(data).then(() => reset(data));
  };
  const { fields, remove, insert } = useFieldArray({
    name: configName,
    control,
  });
  const handleChange = (event: any) => {
    let value = event.target.value.replace(/^0+(?=\d)/, ""); // Remove leading zeros
    value = value.replace(/[^\d]/g, ""); // Remove non-numeric
    event.target.value = value; // Set the transformed value back to the input
  };
  useEffect(() => {
    reset(
      configData && configData[configName]
        ? { [configName]: configData[configName] }
        : {
            [configName]: [
              { type: CaseTypes.lesserThan, value: [0] },
              { type: CaseTypes.betweenAnd, value: [0, 0] },
              { type: CaseTypes.greaterThan, value: [0] },
            ],
          }
    );
  }, [configData, configName, reset]);

  const validateCaseConditions = (
    conditions: {
      value: number[];
      type: CaseTypes;
    }[]
  ) => {
    const ranges = conditions.filter(
      (condition) => condition.type === CaseTypes.betweenAnd
    );
    const lesserThan = conditions.filter(
      (condition) => condition.type === CaseTypes.lesserThan
    );
    const greaterThan = conditions.filter(
      (condition) => condition.type === CaseTypes.greaterThan
    );

    const isOverlapping = (
      start1: number,
      end1: number,
      start2: number,
      end2: number
    ) => start1 < end2 && end1 >= start2;

    // Check for overlapping within "betweenAnd" ranges
    for (let i = 0; i < ranges.length; i++) {
      const [start1, end1] = ranges[i].value;
      if (start1 >= end1) {
        return "from value should be lesser than to ";
      }
      for (let j = i + 1; j < ranges.length; j++) {
        const [start2, end2] = ranges[j].value;

        if (isOverlapping(start1, end1, start2, end2)) {
          return "BetweenAnd ranges should not overlap";
        }
      }
    }

    // Check for overlapping between "lesserThan" and other ranges
    for (let i = 0; i < lesserThan.length; i++) {
      const maxLesserThan = lesserThan[i].value[0];
      for (let j = 0; j < ranges.length; j++) {
        const [start, end] = ranges[j].value;
        if (maxLesserThan >= Math.min(start, end)) {
          return "Lesser than range should not overlap with between range";
        }
      }
      for (let j = 0; j < greaterThan.length; j++) {
        const minGreaterThan = greaterThan[j].value[0];
        if (maxLesserThan >= minGreaterThan) {
          return "Lesser than range should not overlap with greater than range";
        }
      }
    }

    // Check for overlapping between "greaterThan" and other ranges
    for (let i = 0; i < greaterThan.length; i++) {
      const minGreaterThan = greaterThan[i].value[0];
      for (let j = 0; j < ranges.length; j++) {
        const [start, end] = ranges[j].value;
        if (minGreaterThan <= Math.max(start, end)) {
          return "Greater than range should not overlap with between range";
        }
      }
      for (let j = 0; j < lesserThan.length; j++) {
        const maxLesserThan = lesserThan[j].value[0];
        if (minGreaterThan <= maxLesserThan) {
          return "Greater than range should not overlap with lesser than range";
        }
      }
    }

    return true;
  };
  return (
    <Paper
      component="form"
      elevation={0}
      id={styles.CaseConfig}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box className={styles.header}>
        <Header title={title} variant={"h6"} description={description}></Header>
        <Box className={styles.saveButtonContainer}>
          {!isLoading && (
            <LoadingButton
              // sx={{ mt: 2 }}
              loading={isSubmitting}
              disabled={!isDirty}
              variant="contained"
              type="submit"
            >
              Save configuration
            </LoadingButton>
          )}
        </Box>
      </Box>

      {!isLoading ? (
        <Card className={styles.caseCard}>
          {fields.map(({ type, id }, index) => (
            <Box className={styles.caseConditionBox} key={id}>
              <TextField
                type={"string"}
                label={"Display name"}
                className={styles.periodName}
                {...register(`${configName}.${index}.tag`, {})}
              ></TextField>
              {type === CaseTypes.lesserThan ? (
                <Box className={styles.caseConditionSingleBox}>
                  <Typography
                    className={styles.conditionName}
                    color={"primary"}
                  >
                    {"<="}
                  </Typography>
                  <TextField
                    type={"number"}
                    label={configLabel}
                    className={styles.daysField}
                    inputProps={{ min: 1 }}
                    required
                    {...register(`${configName}.${index}.value.0`, {
                      onChange: (event) => handleChange(event),
                      valueAsNumber: true,
                    })}
                  ></TextField>
                </Box>
              ) : type === CaseTypes.betweenAnd ? (
                <Box className={styles.caseConditionSingleBox}>
                  <Typography
                    className={styles.conditionName}
                    color={"primary"}
                  >
                    {"from"}
                  </Typography>
                  <TextField
                    type={"number"}
                    label={configLabel}
                    className={styles.daysField}
                    inputProps={{ min: 1 }}
                    required
                    {...register(`${configName}.${index}.value.0`, {
                      onChange: (event) => handleChange(event),
                      valueAsNumber: true,
                    })}
                  ></TextField>
                </Box>
              ) : type === CaseTypes.greaterThan ? (
                <Box className={styles.caseConditionSingleBox}>
                  <Typography
                    className={styles.conditionName}
                    color={"primary"}
                  >
                    {">="}
                  </Typography>
                  <TextField
                    type={"number"}
                    label={configLabel}
                    className={styles.daysField}
                    inputProps={{ min: 1 }}
                    required
                    {...register(`${configName}.${index}.value.0`, {
                      onChange: (event) => handleChange(event),
                      valueAsNumber: true,
                    })}
                  ></TextField>
                </Box>
              ) : (
                <>...</>
              )}

              {/*  to textbox, delete and add  */}
              {type === CaseTypes.betweenAnd ? (
                <Box className={styles.caseConditionSingleBox}>
                  <Box className={styles.betweenCondition}>
                    <Typography
                      className={styles.conditionName}
                      color={"primary"}
                    >
                      {"to "}
                    </Typography>

                    <TextField
                      type={"number"}
                      label={configLabel}
                      className={styles.daysField}
                      inputProps={{ min: 1 }}
                      required
                      {...register(`${configName}.${index}.value.1`, {
                        onChange: (event) => handleChange(event),
                        valueAsNumber: true,
                      })}
                    />
                  </Box>
                  <Box className={styles.Button}>
                    <IconButton
                      className={styles.deleteButton}
                      onClick={() => {
                        if (fields.length <= 3) {
                          toast.error("Atleast one condition required");
                        } else {
                          remove(index);
                        }
                      }}
                    >
                      <DeleteIcon color="inherit" />
                    </IconButton>
                    {fields.length - 2 === index && (
                      <IconButton
                        color="primary"
                        onClick={() => {
                          insert(index + 1, {
                            tag: "",
                            type: CaseTypes.betweenAnd,
                            value: [0, 0],
                          });
                        }}
                      >
                        <AddCircleOutlineIcon color="inherit" />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          ))}
        </Card>
      ) : (
        <CaseConfigLoader />
      )}
    </Paper>
  );
}
